import React, {useRef, useEffect} from 'react'
import './Skills.css'
import { useTranslation } from 'react-i18next';
import SkillsBrands from '../../../assets/skills_brands/SkillsBrands';

const Skills = ({triggerSkillsAnimation, setTriggerSkillsAnimation, listenScrollSection, direction}) => {
  const [t, i18n] = useTranslation("global");
  const skillsRef = useRef();

  useEffect(() => {
    const skills_observer = new IntersectionObserver((entries)=>{
      const entry = entries[0];
      if(entry.isIntersecting){
        listenScrollSection(1);
        setTriggerSkillsAnimation(true);
      }
    });
    skills_observer.observe(skillsRef.current);
  },[]);

  const skillsTitle = t("skills.title");
  const skillsQuote = t("skills.quote");
  const skillsSubtitle = t("skills.subtitle");
  const skillsSubtitle_mobile = t("skills.subtitle_mobile");

  return (
    <div className={`skills-section section`}>
      <div>
        <h1 className="font-60 skills-title section-title">
          {
            skillsTitle.split('').map((l,i) => <span className={l === '|' ? 'space' : ''} key={i}>{l === '|' ? ' ': l}</span>)
          }
        </h1>
        <h2 className='section-quote'>"{skillsQuote}"</h2>
        <p className='skills-subtitle section-subtitle desktop'>
          {skillsSubtitle}
        </p>
        <p className='skills-subtitle section-subtitle mobile'>
          {skillsSubtitle_mobile}
        </p>
      </div>
      <div className={`skills_brands grid grid-cols-8`} ref={skillsRef}>
        <SkillsBrands triggerSkillsAnimation={triggerSkillsAnimation}></SkillsBrands>
      </div>
    </div>
  )
}

export default Skills