import React, {useState, useEffect, useRef} from 'react';
import './Fullpage.css'
import 'fullpage.js/dist/fullpage.css'

import Home from '../sections/home/Home';
import Skills from '../sections/skills/Skills';
import ParallaxSkills from '../parallax/ParallaxSkills';
import ParallaxProjects from '../parallax/ParallaxProjects';
import ParallaxAttitude from '../parallax/ParallaxAttitude';
import ParallaxContacts from '../parallax/ParallaxContacts';
import Projects from '../sections/projects/Projects';
import Attitude from '../sections/attitude/Attitude';
import Contacts from '../sections/contacts/Contacts';

  const Fullpage = ({listenScrollSection}) => {
    
    const [triggerSkillsAnimation, setTriggerSkillsAnimation] = useState(false);
    const [triggerAttitudeAnimation, setTriggerAttitudeAnimation] = useState(false);

    const homeRef = useRef();
    const projectsRef = useRef();
    const attitudeRef = useRef();
    const contactsRef = useRef();

    const skills_observer = (triggerAnimation) => {
      setTriggerSkillsAnimation(triggerAnimation);
      if(triggerAnimation)
        setTriggerAttitudeAnimation(false);
  }
  
    useEffect(() => {
      const home_observer = new IntersectionObserver((entries)=>{
        const entry = entries[0];
        if(entry.isIntersecting){
          listenScrollSection(0);
          setTriggerSkillsAnimation(false);
          setTriggerAttitudeAnimation(false);
        }
      });
  
      const attitude_observer = new IntersectionObserver((entries)=>{
        const entry = entries[0];
        if(entry.isIntersecting){
          listenScrollSection(2);
          setTriggerSkillsAnimation(false);
        }  
      });

      const projects_observer = new IntersectionObserver((entries)=>{
        const entry = entries[0];
        if(entry.isIntersecting){
          listenScrollSection(3);
          setTriggerSkillsAnimation(false);
          setTriggerAttitudeAnimation(false);

        }
      });

      const contacts_observer = new IntersectionObserver((entries)=>{
        const entry = entries[0];
        if(entry.isIntersecting){
          listenScrollSection(4);
          setTriggerSkillsAnimation(false);
          setTriggerAttitudeAnimation(false);
        }
      });
  

  
      home_observer.observe(homeRef.current);
      projects_observer.observe(projectsRef.current);
      attitude_observer.observe(attitudeRef.current);
      contacts_observer.observe(contactsRef.current);

    },[]);

    return(
      <div className='fullpage'>
        <div id='home-container'>
          <section ref={homeRef} id='home' className='section-container'>
            <Home direction={"down"}></Home>
          </section>
        </div>
        <div className='section section-parallax'>
          <ParallaxSkills key={"parallax-skills"} type="skills"></ParallaxSkills>
        </div>
        <div id='skills-container'>
          <section id='skills' className='section-container'>
            <Skills triggerSkillsAnimation={triggerSkillsAnimation} setTriggerSkillsAnimation={skills_observer} listenScrollSection={listenScrollSection}></Skills>
          </section>
        </div>
        <div className='section section-parallax'>
          <ParallaxAttitude key={"parallax-attitude"} type="attitude"></ParallaxAttitude>
        </div>
        <div id="attitude-container">
          <section ref={attitudeRef} id="attitude" className='section-container'>
            <Attitude triggerAttitudeAnimation={triggerAttitudeAnimation} setTriggerAttitudeAnimation={setTriggerAttitudeAnimation}></Attitude>
          </section>
        </div>
        <div className='section section-parallax'>
          <ParallaxProjects key={"parallax-projects"} type="projects"></ParallaxProjects>
        </div>
        <div id="projects-container">
          <section ref={projectsRef} id="projects" className='section-container'>
            <Projects></Projects>
          </section>
        </div>
        <div className='section section-parallax'>
          <ParallaxContacts key={"parallax-contacts"} type="contacts"></ParallaxContacts>
        </div>
        <div id="contacts-container">
          <section ref={contactsRef} id="contacts" className='section-container'>
            <Contacts></Contacts>
          </section>
        </div>
      </div>
    )
  };
  
  export default Fullpage;
  