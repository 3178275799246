import React, {useRef} from 'react'
import "./Parallax.css"
import {motion, useScroll, useTransform} from "framer-motion"
import StarsContainer from '../particles-container/StarsContainer';
import { useTranslation } from 'react-i18next';

function ParallaxProjects() {
  const [t, i18n] = useTranslation("global");
  const ref = useRef();
  const projectsText = t("parallax.projects");

  const {scrollYProgress} = useScroll({
      target:ref,
      offset: ["start start", "end end"]
  });

  const yText = useTransform(scrollYProgress, [0,1], ["-3200%", "1550%"]);
  const yText_mobile = useTransform(scrollYProgress, [0,1], ["-3400%", "1250%"]);
  const yStars = useTransform(scrollYProgress, [0,1], ["-50%", "10%"]);
  const yFloatingRock = useTransform(scrollYProgress, [0,1], ["80%", "-50%"]);
  const xWhale = useTransform(scrollYProgress, [0,1], ["-100%", "60%"]);
  const yWhale = useTransform(scrollYProgress, [0,1], ["-20%", "0%"]);

  return (
    <div className='parallax parallax-projects' 
    style={{background: "linear-gradient(180deg, #0C0914 0%, #0F0B19 100%)"}}>

        <motion.h1 className='text hidden sm:block' style={{y: yText}}> {projectsText} </motion.h1>
        <motion.h1 className='text sm:hidden' style={{y: yText_mobile}}> {projectsText} </motion.h1>
        <motion.div className="parallax-environment environment-projects"></motion.div>
        <motion.div style={{x: xWhale, y: yWhale}} className='parallax-floating floating-projects'></motion.div>
        <motion.div style={{y: yFloatingRock}} className='parallax-floating floating-projects-2'></motion.div>
        <motion.div style={{x: yStars}} className='parallax-stars-particles'><StarsContainer type="projects"></StarsContainer></motion.div>
    </div>
  )
}

export default ParallaxProjects