import React, {useRef} from 'react'
import "./Parallax.css"
import {motion, useScroll, useTransform} from "framer-motion"
import StarsContainer from '../particles-container/StarsContainer';
import { useTranslation } from 'react-i18next';

function ParallaxSkills({type}) {
  const [t, i18n] = useTranslation("global");
  const ref = useRef();
  const skillsText = t("parallax.skills");

  const {scrollYProgress} = useScroll({
      target:ref,
      offset: ["start start", "end end"]
  });

  const yText = useTransform(scrollYProgress, [0,1], ["-300%", "1100%"]);
  const yText_mobile = useTransform(scrollYProgress, [0,1], ["-800%", "4100%"]);
  const yStars = useTransform(scrollYProgress, [0,1], ["-10%", "40%"]);
  const yFloating = useTransform(scrollYProgress, [0,2], ["-20%", "400%"]);
  const yFloating_mobile = useTransform(scrollYProgress, [0,2], ["-20%", "300%"]);

  return (
    <div className='parallax parallax-skills' 
    style={{background: "linear-gradient(180deg, #0C0914, #0F0B19)"}}>

        <motion.h1 className='hidden sm:block' style={{y: yText}}> {skillsText} </motion.h1>
        <motion.h1 className='sm:hidden' style={{y: yText_mobile}}> {skillsText} </motion.h1>
        <motion.div className='parallax-environment environment-skills'></motion.div>
        <motion.div style={{y: yFloating}} className='parallax-floating floating-skills'></motion.div>
        <motion.div style={{y: yFloating_mobile}} className='parallax-floating floating-skills-mobile'></motion.div>
        <motion.div style={{x: yStars}} className='parallax-stars-particles'><StarsContainer type="skills"></StarsContainer></motion.div>
    </div>
  )
}

export default ParallaxSkills