import React from 'react'
import "./Contacts.css"
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";


function Contacts(direction) {
  const [t, i18n] = useTranslation("global");


  const contactsTitle = t("contacts.title");
  const contactsQuote = t("contacts.quote");
  const contactsSubtitle = t("contacts.subtitle");
  const contactsSubtitle_mobile = t("contacts.subtitle_mobile");
  const contactsSend = t("contacts.send");
  const contactsSendMessage = t("contacts.send_message");

  const contacts_email = t("email");
  const contacts_instagram = t("instagram");
  const contacts_whatsapp = t("phone");

  const formFullname = t("form.Fullname");
  const formEmail = t("form.Email");
  const formMessage = t("form.Message");

  return (
    <div className={`contacts-section section`}>
      <div>
        <h1 className="font-60 contacts-title section-title">
          {
            contactsTitle.split('').map((l,i) => <span className={l === '|' ? 'space' : ''} key={i}>{l === '|' ? ' ': l}</span>)
          }
        </h1>
        <h2 className='section-quote'>"{contactsQuote}"</h2>
        <p className='contacts-subtitle section-subtitle desktop'>
          {contactsSubtitle}
        </p>
        <p className='contacts-subtitle section-subtitle mobile'>
          {contactsSubtitle_mobile}
        </p>
        
        <div className='contacts-container xs:w-2/4 sm:w-5/6 md:w-3/4 md:grid md:grid-cols-2 xl:w-3/5 !mt-10'>
          <div className='contacts-options hidden sm:grid sm:grid-cols-2 sm:w-full md:flex md:grid-cols-none md:w-60'>
            <article className='contacts-option'>
              <h2 className='contacts-icon'><MdOutlineEmail /></h2>
              <h4 className='text-md font-medium text-white truncate mt-6 '>Email</h4>
              <h5 className='text-sm text-white truncate mt-4'>{contacts_email}</h5>
              <h5 className='text-sm font-medium hover:underline mt-6'><a target="_blank" rel="noopener noreferrer" href="mailto:stefanocerro@outlook.com">{contactsSendMessage}</a></h5>
            </article>
            <article className='contacts-option'>
              <h2 className='contacts-icon'><FaWhatsapp /></h2>
              <h4 className='text-md font-medium text-white truncate mt-6'>WhatsApp</h4>
              <h5 className='text-sm text-white truncate mt-4'>{contacts_whatsapp}</h5>
              <h5 className='text-sm font-medium hover:underline mt-6'><a target="_blank" rel="noopener noreferrer" href="https://wa.me/+393703471081">{contactsSendMessage}</a></h5>
            </article>
          </div>
          <form action="" id='contacts-form' className='sm:mt-6 md:mt-0'>
            <input className='contacts-form-field text-sm' type='text' name='name' placeholder={formFullname} required></input>
            <input className='contacts-form-field text-sm' type='email' name='email' placeholder={formEmail} required></input>
            <textarea className='contacts-form-field text-sm' name='message' rows="7" placeholder={formMessage} required></textarea>
            <button className='contacts-form-field contacts-form-submit text-sm' type='button' disabled>{contactsSend}</button>
          </form>
          <div className='mobile-contacts-options ml-2 sm:hidden'>
            <article className='mobile-contacts-option flex mt-12'>
                <h2 className='mobile-contacts-icon'><MdOutlineEmail /></h2>
                <h5 className='text-sm text-white ml-6'><a target="_blank" rel="noopener noreferrer" href="mailto:stefanocerro@outlook.com">{contacts_email}</a></h5>
            </article>
            <article className='mobile-contacts-option flex mt-4'>
              <h2 className='mobile-contacts-icon'><FaInstagram /></h2>
              <h5 className='text-sm text-white ml-6'><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/ste.c_">{contacts_instagram}</a></h5>
            </article>
            <article className='mobile-contacts-option flex mt-4'>
              <h2 className='mobile-contacts-icon'><FaWhatsapp /></h2>
              <h5 className='text-sm text-white ml-6'><a target="_blank" rel="noopener noreferrer" href="https://wa.me/+393703471081">{contacts_whatsapp}</a></h5>
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts