import React from 'react'
import './Home.css'
import ComputerGraphic from '../../../assets/computer-graphic/ComputerGraphic'
import ParticlesContainer from '../../particles-container/ParticlesContainer';
import { useTranslation } from 'react-i18next';

const Home = (direction) => {

  const [t, i18n] = useTranslation("global");

  const mainTitlePt1 = t("home.title");
  const subTitle = t("home.subtitle");
  const content_firstLine = t("home.content_firstline");
  const content_secondLine = t("home.content_secondline");


  return (
    <div className="home">        
      <div className="home-text-container">
        <div>
          <h1 className="font-60 home-title">
            {
              mainTitlePt1.split('').map((l, i) => <span className={l === '|' ? 'space' : ''} key={i}>{l === '|' ? ' ': l}</span>)
            }
          </h1>
          
          <h1 className="home-subtitle">
            <p>{subTitle}</p>
          </h1>
        </div>
        <p className="home-about-me">{content_firstLine} <br/> {content_secondLine}</p>
      </div>
      <div className="home-graphic">
        <ParticlesContainer />
        <ComputerGraphic />
      </div>
    </div>
  )
}

export default Home