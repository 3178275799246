import React, { createRef, useEffect, useState } from 'react'
import './OverlayMobile.css'
import $ from 'jquery';

import { IoHome } from "react-icons/io5";
import { IoBook } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa6";
import { IoGrid } from "react-icons/io5";
import { AiFillMessage } from "react-icons/ai";


function OverlayMobile({currentSection}) {

    const [activeNav, setActiveNav] = useState("#home");
    
    useEffect(() => {
      listenerOverSection(currentSection);
    }, [currentSection]);

    const listenerOverSection = (section) => {
      switch(section){
        case 0:
            $(".nav_mobile__wrapper a").removeClass("active");
            $(".nav_mobile__wrapper #nav_option_home").addClass("active");
          break;
        case 1:
            $(".nav_mobile__wrapper a").removeClass("active");
            $(".nav_mobile__wrapper #nav_option_skills").addClass("active");
          break;
        case 2:
            $(".nav_mobile__wrapper a").removeClass("active");
            $(".nav_mobile__wrapper #nav_option_attitude").addClass("active");
          break;
        case 3:
            $(".nav_mobile__wrapper a").removeClass("active");
            $(".nav_mobile__wrapper #nav_option_projects").addClass("active");
          break;
        case 4:
            $(".nav_mobile__wrapper a").removeClass("active");
            $(".nav_mobile__wrapper #nav_option_contacts").addClass("active");
          break;
      }
    }
    
  return (
    <nav className='nav_mobile__wrapper'>
        <a id="nav_option_home" href='#home' onClick={() => setActiveNav("#home")} className={activeNav == '#home' ? 'active' : '' }><IoHome></IoHome></a>
        <a id="nav_option_skills" href='#skills' onClick={() => setActiveNav("#skills")} className={activeNav == '#skills' ? 'active' : '' }><IoBook></IoBook></a>
        <a id="nav_option_attitude" href='#attitude' onClick={() => setActiveNav("#attitude")} className={activeNav == '#attitude' ? 'active' : '' }><FaUserTie></FaUserTie></a>
        <a id="nav_option_projects" href='#projects' onClick={() => setActiveNav("#projects")} className={activeNav == '#projects' ? 'active' : '' }><IoGrid></IoGrid></a>
        <a id="nav_option_contacts" href='#contacts' onClick={() => setActiveNav("#contacts")} className={activeNav == '#contacts' ? 'active' : '' }><AiFillMessage></AiFillMessage></a>
    </nav>
  )
}

export default OverlayMobile