import './App.css';
import React, {useState} from 'react';
import Fullpage from './components/fullpage/Fullpage';
import OverlaySideLeft from './components/overlay-sides/OverlaySideLeft';
import OverlaySideRight from './components/overlay-sides/OverlaySideRight';
import OverlayMobile from './components/overlay-sides/OverlayMobile';

function App() {
  const [currentSection, setCurrentSection] = useState(0);

  const listenScrollSection = (n_section) => {
    setCurrentSection(n_section);
  }

  return (
    <div className='App' style={{height: "900px"}}>
      <OverlaySideLeft />
      <Fullpage 
          listenScrollSection = {listenScrollSection}
        />      

      <OverlaySideRight currentSection={currentSection}/>
      <OverlayMobile currentSection={currentSection}></OverlayMobile>
      <div className="mobile_nav_bg"></div>
    </div>
  )
}

export default App;
