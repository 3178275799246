import React, {useRef} from 'react'
import "./Parallax.css"
import {motion, useScroll, useTransform} from "framer-motion"
import StarsContainer from '../particles-container/StarsContainer';
import { useTranslation } from 'react-i18next';

function ParallaxAttitude() {
  const [t, i18n] = useTranslation("global");
  const ref = useRef();
  const attitudeText = t("parallax.attitude");

  const {scrollYProgress} = useScroll({
      target:ref,
      offset: ["start start", "end end"]
  });

  const yText = useTransform(scrollYProgress, [0,1], ["-1300%", "1400%"]);
  const yText_mobile = useTransform(scrollYProgress, [0,1], ["-1800%", "2400%"]);
  const yStars = useTransform(scrollYProgress, [0,1], ["-30%", "30%"]);
  const yFloating = useTransform(scrollYProgress, [0,2], ["-80%", "280%"]);
  const reverseYFloating = useTransform(scrollYProgress, [0,2], ["120%", "-500%"]);

  return (
    <div className='parallax parallax-attitude' 
    style={{background: "linear-gradient(180deg, #0C0914 0%, #0F0B19 100%)"}}>

        <motion.h1 className='text hidden sm:block' style={{y: yText}}> {attitudeText} </motion.h1>
        <motion.h1 className='text sm:hidden' style={{y: yText_mobile}}> {attitudeText} </motion.h1>
        <motion.div className="parallax-environment environment-attitude"></motion.div>
        <motion.div style={{y: yFloating}} className='parallax-floating floating-attitude'></motion.div>
        <motion.div style={{y: reverseYFloating}} className='parallax-floating floating-attitude-mobile'></motion.div>
        <motion.div style={{x: yStars}} className='parallax-stars-particles'><StarsContainer type="attitude"></StarsContainer></motion.div>
    </div>
  )
}

export default ParallaxAttitude