import React, { createRef, useEffect, useState } from 'react'
import './OverlaySides.css'
import $ from 'jquery';
import { useTranslation } from 'react-i18next';


const sections = ['home', 'skills', 'attitude', 'projects', 'contacts']
const sideNav = createRef()
  
export const CheckIsActive = ({direction}) => {
  const list = sideNav.current.childNodes

  for (let i = 0; i < list.length; i++) {
    if (i < direction && !list[direction].classList.contains('active')) {
      list[i].classList.add('passedSection');
      list[i].classList.remove('currentSection');
    } else {
      if(i == direction){
        list[i].classList.remove('passedSection');
        list[i].classList.add('currentSection');
      }
      else{
        list[i].classList.remove('passedSection');
        list[i].classList.remove('currentSection');
      }
    }
  }
}




const OverlaySideRight = ({currentSection}) => {
  const [hitBottom, setHitBottom] = useState(false);  
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    listenerOverSection(currentSection);
    //first
    if(currentSection == 0)
      setHitBottom(false);
    //last
    if(currentSection == 3)
      setHitBottom(true);
  }, [currentSection]);


  const listenerOverSection = (section) => {
    switch(section){
      case 0:
        $("#side_nav .line").css("transform", "translateY(0px)");
        break;
      case 1:
        $("#side_nav .line").css("transform", "translateY(50px)");
        break;
      case 2:
        $("#side_nav .line").css("transform", "translateY(100px)");
        break;
      case 3:
        $("#side_nav .line").css("transform", "translateY(150px)");
        break;
      case 4:
        $("#side_nav .line").css("transform", "translateY(200px)");
        break;
    }
  }

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <div className="overlay_sides overlay_sides_right">
      {/* <a className="btn_light" href="#contact"> */}
      <a href="#contact">
        {/* Contact */}
      </a>
      <div className='language'>
        <p id="it" onClick={()=> {handleChangeLanguage("it")}}>IT</p>
        <p id="en" onClick={()=> {handleChangeLanguage("en")}}>EN</p>
      </div>
      <nav className="nav__wrapper">
        <ul ref={sideNav} id="side_nav">
          {sections.map((section, i) => (
            <li onClick={() => {CheckIsActive(i)}} onMouseOver={() => {listenerOverSection(i)}} onMouseLeave={() => {listenerOverSection(currentSection)}} data-menuanchor={section} key={i}>
              <a href={'#' + section} title={section}>0{i}</a>
            </li>
          ))}
          <div className="line"></div>
        </ul>
      </nav>

      <div>
        {
          !hitBottom ? 
          <a className='unstyle-link' href={"#"+sections[sections.length-1]}>
            <div className="btn_down">
              <span>Scroll Down</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" fill="aliceblue"/>
              </svg>
            </div>
          </a>
        : 
        <a className='unstyle-link' href={"#"+sections[0]}>
          <div className="btn_up">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" fill="aliceblue"/>
            </svg>
            <span>Back To Top</span>
          </div>
        </a>
        }
      </div>
    </div>
  )
}

export default OverlaySideRight;