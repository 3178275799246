import React, { useEffect } from 'react'
import "./CircularProgressBar.css";
import $ from 'jquery';
import CustomPopover from '../custom-popover/CustomPopover';

let dynamicStyles = null;

var isInit = false;
var addedAnimation = false;

const addAnimation = (body) => {
    if (!dynamicStyles) {
        dynamicStyles = document.createElement('style');
        dynamicStyles.type = 'text/css';
        document.head.appendChild(dynamicStyles);
    }

    dynamicStyles.sheet.insertRule(body, dynamicStyles.length);
}

function CircularProgressBar({value, iter, columns, length, title, description, triggerAttitudeAnimation}) {
    var strokeDashOffset = 615 - (465 * value/100 );
    var time = (50 * value) / 950;
    
    if(!addedAnimation){
        addAnimation(`
            @keyframes anim-${iter} { 
                0%{
                    stroke: #9A9ABA;
                }
                100%{
                    stroke-dashoffset: ${strokeDashOffset};
                    stroke: #9A9ABA;
                }
            }`);
    }

    if(iter === length-1){
        isInit = true;
        addedAnimation = true;
    }
    else{
        if(isInit){
            isInit = false;
        }
    }
        
    useEffect(() => {
        $(document).ready(()=>{
                if(triggerAttitudeAnimation){
                    $(`.circular_wrapper_${iter} circle`).css("animation", `anim-${iter} ${time}s linear forwards`);
                    $(`.circular_wrapper_${iter} circle`).css("animation-delay", `${iter/5}s`);
        
                    let number = $(`.attitude_wrapper #number-${iter}`);
                    let counter = 0;
                    
                    
                    setTimeout(()=>{
                        var progress = setInterval(()=>{
                            if(counter === value || counter > value){
                                number.text(`${value}%`);
                                clearInterval(progress);
                            }
                            else{
                                counter+=1;
                                number.text(`${counter}%`);
                            }
                        }, 50);

                    }, (iter/5)*1000);
                }
                else{
                    $(`.circular_wrapper_${iter} circle`).css("animation", ``);
                }
           
        }, 50);
    
    }, [triggerAttitudeAnimation]);
    


  return (
    <>
        <div className={`circular_container circular_wrapper_${iter}`} > 
            <div className='outer'>
                <div className='inner'>
                    <div id={`number-${iter}`}>
                    </div>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px"> */}
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="120px" height="120px">
                        {/* <circle cx="80" cy="80" r="70" stroke-linecap="round"  /> */}
                        <circle cx="60" cy="60" r="50" strokeLinecap="round"  />
                    </svg>
                </div>
            </div>
            <div className='circular-pb-text'>
                <div className='circular-pb-title'>
                    {title}
                    <span className='circular-pb-popover-container cursor-pointer'>
                        <CustomPopover icon={null} title={title} description={description} elNumber={iter}></CustomPopover>                    
                    </span>
                </div> 
                
                
            </div>
            
            {/* <div className='attitude-description'>{description}</div> */}
        </div>
    </>
  )
}

export default CircularProgressBar