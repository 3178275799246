import React, {useRef, useEffect} from 'react'
import "./Attitude.css"
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../../circular-progressbar/CircularProgressBar';


function Attitude({triggerAttitudeAnimation, setTriggerAttitudeAnimation, direction}) {
  const [t, i18n] = useTranslation("global");
  const attitudeRef = useRef();

  useEffect(() => {
    const attitude_observer = new IntersectionObserver((entries)=>{
      const entry = entries[0];
      if(entry.isIntersecting){
        setTriggerAttitudeAnimation(true);
      }
    });
    attitude_observer.observe(attitudeRef.current);
  },[]);

  const attitudeTitle = t("attitude.title");
  const attitudeQuote = t("attitude.quote");
  const attitudeSubtitle = t("attitude.subtitle");
  const attitudeSubtitleMobile = t("attitude.subtitle_mobile");


  var attitudes = t("attitude.attitudes", { returnObjects: true });

  return (
    <div className={`attitude-section section`}>
      <div>
        <h1 className="font-60 attitude-title section-title">
          {
            attitudeTitle.split('').map((l,i) => <span className={l === '|' ? 'space' : ''} key={i}>{l === '|' ? ' ': l}</span>)
          }
        </h1>
        <h2 className='section-quote'>"{attitudeQuote}"</h2>
        <p className='attitude-subtitle section-subtitle desktop'>
          {attitudeSubtitle}
        </p>
        <p className='attitude-subtitle section-subtitle mobile'>
          {attitudeSubtitleMobile}
        </p>
      </div>
      <div className='attitude_wrapper grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5' ref={attitudeRef}>
        {
        attitudes.map((attitude, i) => {
          return(
            <CircularProgressBar key={i} value={attitude.value} iter={i} columns={5} length={attitudes.length} title={attitude.title} description={attitude.description} triggerAttitudeAnimation={triggerAttitudeAnimation}></CircularProgressBar>
          )
        })}
      </div>
    </div>
  )
}

export default Attitude