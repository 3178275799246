import React, {useEffect, useMemo, useState} from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {loadFull} from 'tsparticles'
import "./ParticlesContainer.css";

function ParticlesContainer() {

    const [init, setInit] = useState(false);


    useEffect(() => {
        initParticlesEngine(async (engine) => {
          await loadFull(engine);
        }).then(() => {
          setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
    };
    
    const options = useMemo(
    () => (
        {
        fullScreen:{
            enable:false
        },
        fps_limit:120,
        interactivity:{
        detectsOn:"canvas",
        events:{
            onClick:{
            enable:true,
            mode:"push"
            },
            onHover:{
            enable:true,
            mode:"repulse"
            },
            resize:true
        },
        modes:{
            push:{
                quantity:5
            },
            repulse:{
            distance:100,
            duration:0.41
            }
        }
        },
        particles:{
        color:
        {
            value:"#EEEEF0"
        },
        links:{
            color:"#EEEEF0",
            distance:100,
            enable:true,
            opacity:1,
            width:0.5
        },
        collisions: {
            enable:true
        },
        move:{
            direction:"none",
            enable:true,
            outModes: {
                default: 'bounce'
            },
            random:false,
            speed:.3,
            straight:false
        },
        number:{
            density:{
            enable:true,
            area:800
            },
            value:300
        },
        opacity:{
            value:0.5
        },
        shape:{
            type:"circle"
        },
        size:{
            random:true,
            value:{
                min:1,
                max:5
            }
        }
        },
        // background: {
        // color: "#000000",
        // image: "",
        // position: "50% 50%",
        // repeat: "no-repeat",
        // size: "cover"
        // },
        detectRetina:true
    }),
    [],
    );
    
    if (init) {
        return (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        );
      }
}
export default ParticlesContainer