import React, {useEffect, useMemo, useState} from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {loadFull} from 'tsparticles';

function StarsContainer({type}) {

    const [init, setInit] = useState(false);


    useEffect(() => {
        initParticlesEngine(async (engine) => {
          await loadFull(engine);
        }).then(() => {
          setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
    };
    
    const options = useMemo(
    () => (
        {
            fullScreen:{
                enable:false
            },
            fps_limit:120,
            backgroundMode: {
                enable: true,
                zIndex: 0
              },
              "particles": {
                "number": {
                  "value": 555,
                  "density": {
                    "enable": true,
                    "value_area": 789.1476416322727
                  }
                },
                "color": {
                  "value": "#ffffff"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 5
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 0.48927153781200905,
                  "random": false,
                  "anim": {
                    "enable": true,
                    "speed": 0,
                    "opacity_min": 0,
                    "sync": false
                  }
                },
                "size": {
                  "value": 1.5,
                  "random": true,
                  "anim": {
                    "enable": true,
                    "speed": 0,
                    "size_min": 0,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 150,
                  "color": "#ffffff",
                  "opacity": 0.4,
                  "width": 1
                },
                "move": {
                  "enable": true,
                  "speed": 0.1,
                  "direction": "none",
                  "random": true,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "bubble"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "push"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 83.91608391608392,
                    "size": 2,
                    "duration": 3,
                    "opacity": 1,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 200,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              "retina_detect": true
        }
    ),
    [],
    );
    
    if (init) {
        return (
          <Particles
            id={"tsparticles-stars-" + type}
            particlesLoaded={particlesLoaded}
            options={options}
          />
        );
      }
}
export default StarsContainer